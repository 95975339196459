import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['vendorIdInput', 'itemIdInput', 'classIdInput', 'departmentIdInput']

  vendorIdInputTargetConnected(element) {
    $(element).selectize({
      ...this.baseSelectizeOptions,
      ...this.selectizeDefaultHandlers(this.vendorIdInputTarget)
    })
  }

  itemIdInputTargetConnected(element) {
    $(element).selectize({
      ...this.baseSelectizeOptions,
      ...this.selectizeDefaultHandlers(this.itemIdInputTarget)
    })
  }

  classIdInputTargetConnected(element) {
    $(element).selectize({
      ...this.baseSelectizeOptions,
      ...this.selectizeDefaultHandlers(this.classIdInputTarget)
    })
  }

  departmentIdInputTargetConnected(element) {
    $(element).selectize({
      ...this.baseSelectizeOptions,
      ...this.selectizeDefaultHandlers(this.departmentIdInputTarget)
    })
  }

  get baseSelectizeOptions() {
    return {
      maxItems: 1,
      selectOnTab: true
    }
  }

  // Overrides the default selectize handlers to also trigger the default form validations
  selectizeDefaultHandlers(targetElement) {
    return {
      onBlur: () => this.dispatchFormValidationEvents(targetElement),
      onChange: value => {
        targetElement.value = value
        this.dispatchFormValidationEvents(targetElement)
      }
    }
  }

  // Dispatches/bubbles input and blur events to trigger default form validation
  dispatchFormValidationEvents(targetElement) {
    targetElement.dispatchEvent(new Event('input', { bubbles: true }))
    targetElement.dispatchEvent(new Event('blur', { bubbles: true }))
  }
}
