import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['enabledCheckbox', 'valueSelect']

  connect() {
    this.boundToggleValueSelect()
  }

  enabledCheckboxTargetConnected(element) {
    element.addEventListener('change', this.boundToggleValueSelect)
  }

  enabledCheckboxTargetDisconnected(element) {
    element.removeEventListener('change', this.boundToggleValueSelect)
  }

  toggleValueSelect(_event) {
    const checkboxEnabled = this.enabledCheckboxTarget.checked

    if (!!checkboxEnabled) {
      this.valueSelectTarget.removeAttribute('disabled')
      this.valueSelectTarget.classList.toggle('d-none', false)
    } else {
      this.valueSelectTarget.setAttribute('disabled', 'disabled')
      this.valueSelectTarget.classList.toggle('d-none', true)
    }
  }

  get boundToggleValueSelect() {
    return this.toggleValueSelect.bind(this)
  }
}
