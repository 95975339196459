import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['copySource', 'copyIcon']

  async copyToClipboard(event) {
    try {
      event.stopPropagation()
      event.preventDefault()

      const contentToCopy = this.copySourceTarget.value

      if (!!contentToCopy && contentToCopy.length) {
        await navigator.clipboard.writeText(contentToCopy)
        this.copyIconTarget.className = 'fas fa-check copy-icon text'
        setTimeout(() => { this.copyIconTarget.className = 'far fa-copy copy-icon' }, 2000)
      }
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  }
}
