import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'
import { resolveCarrierByTrackingNumber } from '@utils/carriers'

export default class extends Controller {
  static targets = ['trackingNumberInput', 'carrierInput']

  trackingNumberInputTargetConnected(element) {
    element.addEventListener('keyup', this.debouncedTrackingNumberChangeHandler)
  }

  trackingNumberInputTargetDisconnected(element) {
    element.removeEventListener('keyup', this.debouncedTrackingNumberChangeHandler)
  }

  get boundTrackingNumberChangeHandler() {
    return this.handleTrackingNumberChange.bind(this)
  }

  get debouncedTrackingNumberChangeHandler() {
    return debounce(this.boundTrackingNumberChangeHandler, 250, { trailing: true })
  }

  async handleTrackingNumberChange(event) {
    const trackingNumber = event.target.value

    if (!!trackingNumber && trackingNumber.length) {
      try {
        const resolvedCarrier = await resolveCarrierByTrackingNumber(trackingNumber)

        if (this.hasCarrierInputTarget)
          this.carrierInputTarget.value = resolvedCarrier
      } catch (e) {
        console.log(e)
        if (this.hasCarrierInputTarget) this.carrierInputTarget.value = ''
      }
    } else {
      if (this.hasCarrierInputTarget) this.carrierInputTarget.value = ''
    }
  }
}
