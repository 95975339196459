export const resolveCarrierByTrackingNumber = async (trackingNumber) => {
  const response = await fetch(`/api/v1/carriers/resolve?tracking_number=${trackingNumber}`)

  if (!response.ok)
    throw new Error(`an error occurred when trying to resolve the carrier for the tracking number ${trackingNumber} (HTTP Status: ${response.status})`)

  const responseJson = await response.json()
  const resolvedCarrier = responseJson.data.carrier

  if (resolvedCarrier === null || resolvedCarrier === undefined || resolvedCarrier === '')
    throw new Error(`could not resolve the carrier for the tracking number ${trackingNumber}`)

  return resolvedCarrier
}
