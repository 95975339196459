import { Controller } from '@hotwired/stimulus'

/**
 * Usage: <%= form_with(url:, model:, method: :post, html: { data: { controller: 'form' } }) do |f| %>
 * */

export default class extends Controller {
  static targets = ['submitButton']

  connect () {
    this.element.setAttribute('novalidate', true)

    // Add event listeners
    this.element.addEventListener('blur', this.onBlur, true)
    this.element.addEventListener('submit', this.onSubmit)
    this.element.addEventListener('ajax:beforeSend', this.onSubmit)
    this.element.addEventListener('change', this.validateSubmit)
    this.element.addEventListener('input', this.validateSubmit)
    this.element.addEventListener('select', this.validateSubmit)

    if (!!this.submitButton) this.submitButton.disabled = true
    if (this.hasSubmitButtonTarget) this.submitButtonTargets.forEach(btn => btn.disabled = true)

    this.validateSubmit();
  }

  disconnect () {
    // Remove event listeners
    this.element.removeEventListener('blur', this.onBlur)
    this.element.removeEventListener('submit', this.onSubmit)
    this.element.removeEventListener('ajax:beforeSend', this.onSubmit)
    this.element.removeEventListener('change', this.validateSubmit)
    this.element.removeEventListener('input', this.validateSubmit)
    this.element.removeEventListener('select', this.validateSubmit)
  }

  onBlur = (event) => {
    this.validateField(event.target)
  }

  validateSubmit = (_event) => {
    if (!!this.submitButton) this.submitButton.disabled = this.firstInvalidField != null
    if (this.hasSubmitButtonTarget) this.submitButtonTargets.forEach(btn => btn.disabled = this.firstInvalidField != null)
  }

  onSubmit = (event) => {
    if (!this.validateForm()) {
      event.preventDefault()
      this.firstInvalidField.focus()
    }
  }

  validateForm () {
    let isValid = true

    // Not using `find` because we want to validate all the fields
    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) isValid = false
    })

    return isValid
  }

  validateField (field) {
    if (!this.shouldValidateField(field)) return true

    const isValid = field.checkValidity()
    field.classList.toggle('invalid', !isValid)

    if (field.labels != null && field.labels[0] != null) field.labels[0].classList.toggle('invalid', !isValid)
    if (!isValid) {
      if (!!this.submitButton) this.submitButton.disabled = true
      if (this.hasSubmitButtonTarget) this.submitButtonTargets.forEach(btn => btn.disabled = true)
    }

    this.refreshErrorForInvalidField(field, isValid)
    return isValid
  }

  shouldValidateField (field) {
    return !field.disabled && !['file', 'reset', 'submit', 'button'].includes(field.type)
  }

  refreshErrorForInvalidField (field, isValid) {
    this.removeExistingErrorMessage(field)
    if (!isValid) this.showErrorForInvalidField(field)
  }

  removeExistingErrorMessage (field) {
    const fieldContainer = field.closest('.field')
    if (!fieldContainer) return;

    const existingErrorMessageElement = fieldContainer.querySelector('.error')

    if (existingErrorMessageElement)
      existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
  }

  showErrorForInvalidField (field) {
    const fieldContainer = field.closest('.field')
    if(!fieldContainer) return;
    fieldContainer.insertAdjacentHTML('beforeend', this.buildFieldErrorHtml(field))
  }

  buildFieldErrorHtml (field) {
    return `<div class="error">${field.validationMessage}</div>`
  }

  get formFields () {
    return Array.from(this.element.elements)
  }

  get firstInvalidField () {
    return this.formFields.find(field => !field.checkValidity())
  }

  get submitButton() {
    if (this.hasSubmitButtonTarget) return null
    return this.element.querySelector('input[type="submit"]')
  }

  // Intended to use when declaring the form.
  //
  // Example:
  //   <%=
  //     form_with(
  //       url: url, model: model, method: method,
  //       html: {
  //         id: 'sample-id',
  //         data: { controller: 'form', action: 'turbo:submit-end->form#reDisableSubmit' }
  //       }
  //     ) do |f|
  //   %>
  //     <%# ... %>
  //   <% end %>
  reDisableSubmit(event) {
    if (event.detail.fetchResponse.response.redirected === true) {
      if (!!this.submitButton) this.submitButton.disabled = true
      if (this.hasSubmitButtonTarget) this.submitButtonTargets.forEach(btn => btn.disabled = true)
    }
  }
}
